import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

export default function Home({ data }) {
  const { t } = useTranslation()
  return (
    <Layout subTitle={t("Home")}>
      <section className="section">
        <div className="columns">
          <div className="column">
            <h1 className="title is-size-1 is-spaced">
              <Trans>Freundeskreis Nürnberg - Glasgow e.V</Trans>
            </h1>
            <h2 className="subtitle is-size-2 is-spaced">
              <Trans>A friendship since 1954</Trans>
            </h2>
            <div className="block">
              <Trans>welcome_text_1</Trans>
            </div>
            <div className="block">
              <Trans>welcome_text_2</Trans>
            </div>
            <Link className="button is-link mt-5 p-4" to="/about">
              <Trans>About Us</Trans>
            </Link>
          </div>
          <div className="column">
            <StaticImage
              src="../images/FreundeskreisNuernbergGlasgow.png"
              alt="Freundeskreis Nürnberg - Glasgow Logo"
              placeholder="blurred"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Banner($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
